class MessageCounterWidget {
  constructor(textArea: HTMLTextAreaElement) {
    this.textArea = textArea;
    this.init();
  }

  private textArea: HTMLTextAreaElement;
  private submitButton: HTMLButtonElement | null = null;
  private progressBar: HTMLProgressElement | null = null;
  private label: HTMLDivElement | null = null;

  private init() {
    // find the required elements
    this.submitButton = document.querySelector('button[type="submit"]');
    this.progressBar = this.textArea.closest('label')?.querySelector('progress') as HTMLProgressElement;
    this.label = this.textArea.closest('label')?.querySelector(`#label-${this.textArea.id}-count`) as HTMLDivElement;

    if (!this.progressBar || !this.label || !this.submitButton) {
      console.error('MessageCounterWidget: Required elements not found.');
      return;
    }

    this.textArea.addEventListener('keyup', () => this.updateCounter());
  }

  private updateCounter() {
    const len = this.textArea.value.length;
    const maxChars = this.progressBar!.max;

    // Update the progress bar and label
    this.progressBar!.value = len;
    this.label!.textContent = `${len}/${maxChars} characters used`;

    // Disable the submit button and mark the textarea as invalid if the max length is exceeded
    if (len >= maxChars) {
      this.submitButton!.classList.add('disabled');
      this.textArea.classList.add('is-invalid');
    } else {
      this.submitButton!.classList.remove('disabled');
      this.textArea.classList.remove('is-invalid');
    }
  }
}


function messageCount(element: HTMLTextAreaElement) {
  if (!element.dataset.messageCounterInit) {
    new MessageCounterWidget(element);
    element.dataset.messageCounterInit = 'true';
  }
}

export default function initMessageCounters() {
  document.querySelectorAll<HTMLTextAreaElement>('textarea.textarea-with-progressbar').forEach((textarea) => {
    messageCount(textarea);
  });
}