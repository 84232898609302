import {connectHits} from "instantsearch.js/es/connectors";
import {html} from 'htm/preact';
import {languageCode} from "./variables.js";

const renderHits = (renderOptions, isFirstRender) => {
    const {hits, results, sendEvent, widgetParams} = renderOptions;

    if (isFirstRender) {
        return;
    }

    widgetParams.container.innerHTML = html`
        ${hits
          .map(
            (item) =>
              `<a class="dropdown-item custom-dropdown-item" href="${languageCode}/search/?${algoliaIndexName}[query]=${item.title}" rel="noopener">${item.title}</a>`
          )
          .join("")}
    `;
};

const customHits = connectHits(renderHits);

export const headHits = customHits({
    container: document.querySelector("#hits"),
});
